import React from "react";

import Icon from 'react-native-vector-icons/Ionicons';

import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts'

function CategoryItem({title, id, index}) {
  // This is the header scroll navigation

  const onPress = () => {
    const section = document.querySelector( '#' + id );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }
  return (

    <a className="link" style={{ backgroundColor: '#8f6552', textDecoration: "none", marginLeft: 0, marginRight: 10, marginTop: 20, marginBottom: 18, borderRadius: 20, cursor: "pointer"}} onClick={() => onPress()}>
      <p style={{color: colors.secondary, fontSize: 14, fontFamily: fonts.bold, paddingLeft: 15, paddingRight: 15, paddingTop: 10  }}>{title}</p>
    </a>

  )
}

export default CategoryItem


//     <TouchableOpacity
//      onPress={onPress}
//      style={}>
//      <Text style={{color: colors.white, fontSize: 14 , fontFamily: fonts.bold }} adjustsFontSizeToFit allowFontScaling>{title}</Text>
//    </TouchableOpacity>
