import React from "react";
import hero1 from "../../assets/1.webp";
import hero2 from "../../assets/2.webp";
import hero3 from "../../assets/3.webp";
import hero4 from "../../assets/4.webp";

import "../styles.css";

function Carousel() {
  return (
    <div
      id="carouselExampleAutoplaying"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={hero1} className="cImg d-block w-100" alt="Cover Image 1" />
          <div className="overlay"></div>
        </div>
        <div className="carousel-item">
          <img src={hero2} className="cImg d-block w-100" alt="Cover Image 2" />
          <div className="overlay"></div>
        </div>
        <div className="carousel-item">
          <img src={hero3} className="cImg d-block w-100" alt="Cover Image 3" />
          <div className="overlay"></div>
        </div>
        <div className="carousel-item">
          <img src={hero4} className="cImg d-block w-100" alt="Cover Image 4" />
          <div className="overlay"></div>
        </div>
      </div>

      {/* Common carousel caption */}
      <div className="carousel-caption d-md-flex justify-content-center align-items-center">
        <div className="text-center">
          <h1 className="hero-heading">Pub.</h1>
          <h1 className="hero-heading">Kitchen.</h1>
          <h1 className="hero-heading">Rooms.</h1>

          <a href="mailto:info@sevenstarsdurham.com" className="hero-link" alt="Link to Email Us">
            <button type="button" className="hero-button">Book Now</button>
          </a>
        </div>
      </div>

      {/* Carousel controls */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default Carousel;
