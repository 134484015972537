import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    Dimensions,
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faSeedling } from '@fortawesome/free-solid-svg-icons/faSeedling'
import { faCarrot } from '@fortawesome/free-solid-svg-icons/faCarrot'
import { faWineGlass } from '@fortawesome/free-solid-svg-icons/faWineGlass'

import Modal from 'modal-enhanced-react-native-web';

import {  useNavigation } from '@react-navigation/native'

import ItemVariantPicker from './ItemVariantPicker'
import ItemModifierPicker from './ItemModifierPicker'


function AddItemButton({ item, index, itemVariant, itemModifiers, itemSetModifiers, itemNote, itemQuantity, onSubmit, selectedItemVariant, selectedItemModifiers, itemVariants, closeItemModal, resetForm, totalItemPrice, isComplete}) {

  const navigation = useNavigation()

  const onPress = () => {
    return (
      closeItemModal(),
      onSubmit(item, index, selectedItemVariant, [...selectedItemModifiers, ...itemSetModifiers], null, itemQuantity),
      resetForm()
    )
  }

  if (isComplete) {
    return (

      <TouchableOpacity
        disabled={isComplete ? false : true}
        onPress={onPress}
        style={itemVariants.length > 0 && selectedItemVariant == "" ? styles.orderButtonDisabled : styles.orderButton}
      >
        <Text style={styles.buttonText} bumberOfLines={1}>Add to Order {'\u22C5'} £{(+totalItemPrice).toFixed(2)}</Text>
      </TouchableOpacity>
    )
  } else {
    return (
      <View></View>
    )
  }

}

export default class ItemAddModal extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      itemNote: "",
      itemQuantity: 1,
      selectedItemVariant: "",
      selectedItemModifiers: []
    }
  }

  resetForm = () => {
    this.setState(state => ({
        itemQuantity: 1,
        itemNote: "",
        selectedItemVariant: "",
        selectedItemModifiers: []
    }))
  }

  increaseQuantity = () => {
    this.setState(state => ({
        itemQuantity: state.itemQuantity + 1
    }))
  }

  decreaseQuantity = () => {
    if (this.state.itemQuantity > 1) {
      this.setState(state => ({
          itemQuantity: state.itemQuantity - 1
      }))
    }
  }

  toggleSelectedVariant = (itemVariant) => {
    this.setState(state => ({
        selectedItemVariant: itemVariant
    }))
  }

  toggleSelectedModifier = (modifier, modifiers) => {

    // Finds if modifier has already been selected.
    let selected = this.state.selectedItemModifiers.filter(m => m.id === modifier.id)

    if (selected.length > 0) {
      // Removes from state if already added.
      this.setState(state => ({
          selectedItemModifiers: state.selectedItemModifiers.filter(m => m.id !== modifier.id)
      }))
    } else {
      // Adds to state if not already been added.
      // See if max value has been met

      let m = [...this.state.selectedItemModifiers]

      m = m.filter(f => f.modifier == modifiers.id)

      if (modifiers.maxSelect == 0 || m.length <= modifiers.maxSelect -1) {
        this.setState(state => ({
            selectedItemModifiers: [...state.selectedItemModifiers, modifier ]
        }))
      }
    }

  }

  render() {

  let totalItemPrice = (this.state.selectedItemVariant ? this.state.selectedItemVariant.price : this.props.item.price) * this.state.itemQuantity

  const itemVariants = this.props.item.id != undefined ? this.props.variantData.filter(variant => variant.item == this.props.item.id) : []
  let itemModifiers = this.props.item.id != undefined ? this.props.modifiers.filter(modifier => modifier.item === this.props.item.id || modifier.category === this.props.item.category) : []
  const itemModifierItems = this.props.item.id != undefined ? this.props.modifierItems : []
  let itemSetModifiers = []
  // This finds any modifiers that are forced to be added onto the item for Set Menus or if customer wants an itemised list of what's inside the item.
  const itemSetModifier = this.props.item.id != undefined ? itemModifiers.filter(modifier => modifier.setModifier === true) : []

  // Works out to see if modifier requirements are met.
  let modFilled = []

  if (itemModifiers.length > 0) {
    let m = [...this.state.selectedItemModifiers]
    itemModifiers.map(mod => {

      const filtered = m.filter(selected => selected.modifier === mod.id)

      if (filtered.length >= mod.minSelect) {
        return modFilled.push(true)
      } else {
        return modFilled.push(false)
      }
    })
  }

  // If there is a Set Modifier, add it's modifier items to the item.
  if (itemSetModifier.length > 0) {
    itemSetModifier.map(modifier => {
      const items = itemModifierItems.filter(item => item.modifier === modifier.id)
      itemSetModifiers = [...itemSetModifiers, ...items]

      itemSetModifiers.sort((a, b) => a.order > b.order ? 1 : -1);
    })
  }


  // Removes set modifiers to allow to be edited
  itemModifiers = itemModifiers.filter(m => m.setModifier !== true)

  return (

    <Modal
      animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
      animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
      isVisible={this.props.isVisible}
      style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
      deviceHeight={Dimensions.get('window').height}
      deviceWidth={Dimensions.get('window').width}
    >
      <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal, { flex: itemVariants.length > 0 || itemModifiers.length > 0 ? 0.9 : 0.3 }]}>
        <View style={styles.header}>
          <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>{this.props.item.name}</Text>
            <TouchableOpacity
              onPress={() => (this.props.closeItemModal(), this.resetForm())}
              style={styles.headerClose}>
              <Icon name="x" size={25} color={colors.textSecondary}/>
          </TouchableOpacity>
        </View>


        <ScrollView
          ref='itemAddModal'
          contentContainerStyle={{ flex: 1 }}
          scrollEnabled={itemVariants.length > 0 || itemModifiers.length > 0 ? true : false}
        >

        {this.props.item.calories || this.props.item.allergens != undefined && this.props.item.allergens.length > 0 ?
          <View style={styles.subHeading}>
            {this.props.item.calories ? <Text style={styles.calories} adjustsFontSizeToFit allowFontScaling>{this.props.item.calories} kcal {this.props.item.calories && this.props.item.allergens != undefined && this.props.item.allergens.length > 0 ? '\u2022' : null}</Text>: null}
            {this.props.item.allergens != undefined ?
              <View style={[styles.allergens, this.props.item.allergens != undefined && this.props.item.allergens.length > 0 && this.props.calories ? { paddingLeft: 10 } : { paddingLeft: 0 }]}>
              {this.props.item.allergens.map(a => {
                return (
                  <View style={styles.allergenTag}>
                    {a ? a.includes('vegan') ? <FontAwesomeIcon style={styles.allergenTagIcon} size={12} icon={ faSeedling } /> : null : null}
                    {a? a.includes('vegetarian') ? <FontAwesomeIcon style={styles.allergenTagIcon} size={12} icon={ faCarrot } /> : null : null}
                    {a ? a.includes('alcohol') ? <FontAwesomeIcon style={styles.allergenTagIcon} size={12} icon={ faWineGlass } /> : null : null}
                    <Text style={styles.allergenTagText}>{a}</Text>
                  </View>
                )
              })}
              </View>
            : null}
          </View>
        : null}

        {this.props.item.description ?
          <Text style={styles.description} adjustsFontSizeToFit allowFontScaling>{this.props.item.description}</Text>
        : null}

        {itemVariants.length > 0 ?
        <View style={{ marginBottom: 40 }}>
        <Text style={{fontSize: 13, fontFamily: fonts.regular, marginTop: 30, marginBottom: 0}} adjustsFontSizeToFit allowFontScaling>Choose an option</Text>

        <ItemVariantPicker
          variants={itemVariants}
          toggleSelectedVariant={(variant) => {
            this.toggleSelectedVariant(variant),
            setTimeout(() => {
              this.refs.itemAddModal.scrollToEnd()
            }, 500);

            }}
          selectedVariant={this.state.selectedItemVariant}
        />
        </View>
        : null}

        {itemModifiers.length > 0 ?
          itemModifiers.map((m, k) => {

            const i = itemModifierItems.filter(item => item.modifier === m.id)

            return (
              <View style={{ marginTop: 10 }} key={k}>
                <Text style={styles.inputTitle}>
                  {m.name}{m.isRequired ? "*" : null }

                  {m.minSelect > 0 || m.maxSelect > 0 ?
                  <Text style={{ color: colors.text, fontFamily: fonts.regular}}>  ({m.minSelect > 0 ? m.minSelect + " Min " : null }{m.maxSelect > 0 ? m.maxSelect + " Max" : null })</Text>
                  : null}
                </Text>

                <ItemModifierPicker
                  key={k.toString()}
                  modifiers={m}
                  modifierItems={i}
                  toggleSelectedModifier={(modifierItem) => this.toggleSelectedModifier(modifierItem, m)}
                  selectedModifiers={this.state.selectedItemModifiers}
                />
              </View>
            )
          })
        : null}

        <View style={{ flex: 1, justifyContent: "flex-end", paddingVertical: 30}}>
        <View style={styles.quantities}>
          <TouchableOpacity
            onPress={() => this.decreaseQuantity()}
            style={styles.quantity}
          >
            <Text style={styles.quantityText} adjustsFontSizeToFit allowFontScaling>-</Text>
          </TouchableOpacity>
          <Text style={[styles.quantityValue, { flex: 1.5, paddingHorizontal: 10 }]} adjustsFontSizeToFit allowFontScaling>{this.state.itemQuantity}</Text>
          <TouchableOpacity
            onPress={() => this.increaseQuantity()}
            style={styles.quantity}
          >
            <Text style={styles.quantityText} adjustsFontSizeToFit allowFontScaling>+</Text>
          </TouchableOpacity>
        </View>
        </View>

        </ScrollView>

        <AddItemButton
          item={this.props.item}
          index={this.props.index}
          itemVariants={itemVariants}
          totalItemPrice={totalItemPrice}
          selectedItemVariant={this.state.selectedItemVariant}
          selectedItemModifiers={this.state.selectedItemModifiers}
          itemSetModifiers={itemSetModifiers}
          itemQuantity={this.state.itemQuantity}
          closeItemModal={() => this.props.closeItemModal()}
          onSubmit={this.props.onSubmit}
          resetForm={() =>this.resetForm()}
          isComplete={this.state.selectedItemVariant != "" && modFilled.every(v => v === true) == true || itemVariants.length == 0 && modFilled.every(v => v === true) == true ? true : false }
        />
      </View>
    </Modal>
  )
}
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
  },
  modal: {
    minHeight: 340,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    padding: 30,
  },
  modalWeb: {
    minHeight: 340,
    backgroundColor:colors.white,
    width: 600,
    minWidth: 500,
    alignSelf: "center",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 30,
  },
  header: {
    flexDirection: "row",
    marginBottom: 5,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 24,
    fontFamily: fonts.bold
  },
  subHeading: {
    marginTop: 5,
    marginBottom: 5,
    flexDirection: "row"
  },
  calories: {
    fontFamily: fonts.regular,
    color: colors.textSecondary,
    fontSize: 14,
    marginBottom: 5,
    marginRight: 5,
  },
  allergens: {
    flexDirection: "row",
    marginTop: -3,
  },
  allergenTag: {
    backgroundColor: colors.primary,
    minWidth: 40,
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: "row",
    borderRadius: 20,
    marginRight: 5,
    opacity: 0.8
  },
  allergenTagText: {
    fontFamily: fonts.regular,
    color: colors.white,
    fontSize: 12,
    textTransform: "capitalize"
  },
  allergenTagIcon: {
    color: colors.white,
    paddingRight: 5,
    position: "relative",
    top: 2,
  },
  description: {
    fontSize: 13,
    marginTop: 5,
    flex: 1,
    fontFamily: fonts.regular,
    color: colors.textSecondary
  },
  orderButton: {
    backgroundColor: colors.primary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
  },
  orderButtonDisabled: {
    backgroundColor: colors.primary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontSize: 15,
    textAlign: "center",
    color: colors.secondary,
    fontFamily: fonts.bold,
  },
  quantities: {
    width: 170,
    height: 60,
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "flex-end",
    justifySelf: "flex-end",
  },
  quantity: {
    flex: 2,
    padding: 10,
    height: 60,
    borderRadius: 50,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    borderColor: colors.border,
    backgroundColor: colors.white
  },
  quantityText: {
    fontSize: 16,
    color: colors.textSecondary,
    fontFamily: fonts.text,
  },
  quantityValue: {
    flex: 0.5,
    padding: 20,
    fontSize: 18,
    textAlign: "center",
    fontFamily: fonts.text,
},
inputTitle: {
  fontSize: 13,
  fontFamily: fonts.text,
}
});
