// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyBm-aHCeiGR0QTwqhwRnsR5mTiLC-WELh8",
  authDomain: "yuze-seven-stars.firebaseapp.com",
  projectId: "yuze-seven-stars",
  storageBucket: "yuze-seven-stars.firebasestorage.app",
  messagingSenderId: "388654895570",
  appId: "1:388654895570:web:8f5111e3c70a080d6e380b",
  measurementId: "G-ETM53HXLYF"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyB9HTMpNuz5XN9Y2rHmGOnVTPIu2Ea10dQ",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:9779b88a82add54ae8dec1",
  measurementId: "G-BJCJW0ZNS1"
};

export { firebaseCompanyConfig, firebaseBackOfficeConfig }
